@import url('https://fonts.googleapis.com/css2?family=Syncopate:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@600&family=Open+Sans:wght@600&family=Roboto&display=swap');
:root {
    --ruby: rgb(226, 33, 12);
    --powder: rgb(223, 223, 223);
    --blue: #0c6291ff;
    --black: rgb(12, 12, 15);
    --grey: #969697;
}

body {
width: 100%;
    height: 100%;
    margin: 0 auto;
}

.app-body {
    font-family: 'Open Sans', sans-serif;
    background-color: var(--black);
    color: var(--powder);
}

/* _____  NAV  _____ */
.nav-container {
    font-family: 'Kanit', sans-serif;
    text-shadow: 1px 1px 1px var(--ruby);
    background-color:  var(--black);
    /* background-image: linear-gradient(rgb(26, 26, 39), rgba(26,26,39,0.1)); */
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 6vh;
    padding-top: 5vh;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    z-index: 1;
    transition: top 0.5s;
}

.nav-link {
    padding: 25px;
    font-size: 6vw;
}

.nav-link:visited, .nav-link:link, .logo-icon:visited, .logo-icon:link{
    color: var(--powder);
    text-decoration: none;
}

.icon{
    font-size: 9vw;
    padding: 0px 25px;
    color: var(--ruby)
}

.word{
    color: var(--ruby);
    background-color: var(--black)
}

.logo-icon {
    width: 9vw;
    transition: .5s ease;
    display: none;
}
.logo-icon:hover {
    width: 9.5vw;
    transition: .5s ease;
}

.resume-nav-link {
    display: none;
}

/* _____ABOUT______ */
.main-title-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 110vh;

}

.main-title-hrz {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.main-title-bot-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 80vw;
}

.main-title-right {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about-body {
    max-width: 78vw;
    font-size: 1.9vh;
    margin-bottom: 0;
}


.resume-link{
    padding: 5px;
    text-align: center;
    font-size: 1.5vh;
}

.headshot {
    display: none;
    width: 25vw;
}
.headshot-two {
    /* display: none;  */
    margin: 3vh;
    border-radius: 500px;
    width: 58vw;
}

.logo-full {
    width: 100vw;
    height: auto;
    margin-left: -3vw;
    margin-bottom: -4vh;

}

.tech-div{
    width: 75vw;
    font-size: 2.5vw;
}

.tech {
    color: var(--ruby);
    text-decoration: underline;
    font-weight: bold;

}



/*_____  PROJECTS  _____ */
.project-page-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 5vh;
    height: auto;
    background-color: var(--grey);
    border-top: 3px solid var(--ruby);
    border-bottom: 3px solid var(--ruby);
}

.project {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 88vw;
    height: auto;
    background-color: var(--powder);
    border: 1px solid var(--ruby);
    padding: 2vh;
    margin: 2vh;
    border-radius: 5px;
}

.project-img{
    display: block;
    width: 85vw;
    border-radius: 1px;
    box-shadow: 1px 1px 4px black;
    transition: .5s ease;
    backface-visibility: hidden;
}

.project-img-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.project-overlay {
    display: flex;
    flex-direction: column-reverse;
    color: var(--black);
    width: 90vw;
}

.title {
    color: white;
    text-shadow: 1px 1px 1px var(--ruby);
    font-family: 'Kanit', sans-serif;
    text-align: center;
    font-size: 5vw;
    text-decoration: underline;
    padding-top: 5vh;
}

.title-dark {
    color: var(--black);
    text-shadow: 1px 1px 1px var(--ruby);
    font-family: 'Open Sans', sans-serif;
    font-size: 4vw;
    text-align: center;
    margin: 2vh;
}

.buttons {
    display: flex;
    justify-content: center;
}

.button {
    margin: 10px 15px;
    width: 30vw;
}

.btn-dark{
    padding:0.35em 1.2em;
    border:0.1em solid var(--black);
    border-radius:0.12em;
    box-sizing: border-box;
    text-decoration:none;
    /* font-family: 'Kanit', sans-serif; */
    font-weight:300;
    color: white;
    background-color: var(--ruby);
    text-align:center;
}

.btn-light{
    display: none;
    width: 15vw;
}

.btn-light:hover{
    transition: .5s ease;
    color: var(--ruby);
    border:0.1em solid var(--ruby);
}

.text{
    margin: 0 auto;
    font-size: 2vh;
}

.tech-in-project {
    width: 75vw;
    color: var(--black)
}

/* _____  CONTACT  _____ */
.contact-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}

.contact-form-container {
    width: 95vw;
    height:50vh;
    color: var(--black);
    background-color: white;
    border: 1px solid var(--ruby);
    border-radius: 2px;
    margin-top: 10vh;
}

.email-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.contact-form-button{
    margin: 25px;
    width: 20vw;
}

.contact-title {
    color: var(--black);
    text-shadow: 1px 1px 1px var(--ruby);
    font-family: 'Kanit', sans-serif;
    text-align: center;
}
.contact-input {
    width: 82vw;
    border: none;
    border-bottom: 1px solid var(--ruby);
    border-radius: 0px;;
    background-color: inherit;
}

.message {
    height: 10vh;
}

/* _____ FOOTER ______ */
.footer-link {
    color: var(--powder);
}

.footer-container {
    font-family: 'Kanit', sans-serif;
    text-align: center;
    padding-top: 2vh;
}

.footer-text {
    text-align: center;
    margin: 0 auto;
    padding: 19px;
}


.link {
    transition: .5s;
}

.link:hover{
    transition: .5s ease;
    text-decoration: underline var(--ruby);
}

::placeholder {
    font-family: 'Kanit', sans-serif;
}


/* ________________  MEDIA  ______________________ */
@media (min-width: 560px) {

    /* _____NAV_____ */
    .nav-container {
        text-shadow: 1px 1px 1px var(--ruby);
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 14vh;
        max-height: 60px;
        padding-top: 2vh;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: var(--ruby);
        font-size: 30px;
        z-index: 1;
    }

    .nav-link {
        font-size: 3vw;
    }

    .nav-links-container {
        padding-right: 2vw;
    }
    .logo-icon {
        display: block;
        width: 15vw;
        transition: .5s ease;
    }

    .resume-nav-link {
        display: inline;
    }

    /*_____ ABOUT_____ */
    .main-title-container {
        height: 110vh;
    }

    .main-title-bot-div {
        display: flex;
        flex-direction: row;
    }

    .main-title-right {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 40vw;
        margin-left: -5vw;
    }

    .headshot {
        display: block;
        margin-right: 7vw;
    }

    .logo-full {
        width: 40vw;
        height: auto;
        margin-left: -6vw;
        padding-bottom: 10px
    }

    .about-body {
        width: 40vw;
        font-size: 1.66vw;
    }

    .headshot {
        border-radius: 500px;
    }

    .headshot-two {
        display: none;
    }

    .tech-div{
        /* width: 65vw; */
        font-size: 1.4vw;
    }

    .resume-link {
        display: none;
    }


    /*______ PROJECTS  ______ */

    .project-img{
        display: block;
        width: 70vw;
        border-radius: 1px;
        box-shadow: 1px 1px 4px black;
        transition: .5s ease;
        backface-visibility: hidden;
    }


    /* _____ FOOTER _____ */
    .icon{
        font-size: 6vw;
        padding: 25px;
    }

    .title {
        margin-top: 5vh
    }

    .footer-container {
        padding-top: 0vh;
    }

    .footer-text {
        padding: 4px;
    }

    /* _____ CONTACT ______ */
    .contact-input {
        padding: 1.5vh;
    }

    .contact-form-container {
        width: 60vw;
        height: auto;
        margin-top: 18vh;
    }

    .contact-input {
        width: 42vw;
    }

    .text {
        font-size: 2vw;
        width: 60vw;
    }


}

@media (min-width: 768px) {
    .nav-link {
        font-size: 2.5vw;
    }

    .logo-icon {
        width: 13vw;
        transition: .5s ease;
    }

    .icon{
        font-size: 4vw;
    }

    .logo-full {
        padding-bottom: 0px;
    }

}

@media (min-width: 992px) {
    .logo-icon {
        width: 10vw;
        transition: .5s ease;
    }
    .icon{
        font-size: 5vw;
    }

/* _____PROJECTS_____ */
    .title{
        font-size: 5.5vh;
    }
    .project-page-container{
        height: auto;
    }

    .project-img{
        display: block;
        width: 60vw;
        height: auto;
        border-radius: 1px;
        transition: .5s ease;
        backface-visibility: hidden;
    }

    .project-img-container{
    position: relative;
    width: 60%;
    box-shadow: 2px 2px 20px black;
}

    .project-overlay {
        /* color: var(--powder); */
        display: flex;
        flex-direction: column-reverse;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        margin: 0 auto;
        opacity: 0;
        transition: .55s ease;
        background-color: var(--black);
    }
    .project {
        display: flex;
        flex-direction: column;
        justify-content: start;
        width: 100vw;
        height: 75vh;
        background-color: inherit;
        border: none;
        padding: 0px;
    }

    .project-img-container:hover .project-overlay {
        opacity: 0.95;
    }

    .text {
        color: white;
        font-size: 1vw;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 40vw;
        padding-bottom: 10px;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
    }

    .btn-light{
        display:inline-block;
        padding:0.35em 1.2em;
        border:0.1em solid white;
        border-radius:0.12em;
        box-sizing: border-box;
        text-decoration:none;
        font-family:'Roboto',sans-serif;
        font-weight:300;
        color: white;
        background-color: inherit;
        text-align:center;
        transition: all 0.2s;
    }
    .btn-hide {
        display: none;
    }
    .button{
        margin: 20px;
        /* width: 25vw; */
    }

    .buttons{
        padding-bottom: 10vh;
    }

    .title-dark {
        display: none;
    }

}

@media (min-width: 1200px) {
    .about-body{
        font-size: 2.5vh;
    }

    .tech-div {
        font-size: 2vh;
    }

    .nav-link {
        font-size: 2vw;
    }

    .contact-form-container {
        width: 60vw;
    }

    .contact-input {
        width: 42vw;
    }

    .icon{
        font-size: 3vw;
    }
    .logo-full {
        padding-bottom: -10px
    }

}

.focus-in-contract-bck {
	-webkit-animation: focus-in-contract-bck 1s cubic-bezier(0.215, 0.610, 0.355, 0.500) both;
	animation: focus-in-contract-bck 1s cubic-bezier(0.215, 0.610, 0.355, 0.500) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-2-6 15:29:57
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation focus-in-contract-bck
 * ----------------------------------------
 */
@-webkit-keyframes focus-in-contract-bck {
    0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
    transform: translateZ(300px);
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
    }
    100% {
    -webkit-transform: translateZ(12px);
    transform: translateZ(12px);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
    }
    }
@keyframes focus-in-contract-bck {
    0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
    transform: translateZ(300px);
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
    }
    100% {
    -webkit-transform: translateZ(12px);
    transform: translateZ(12px);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
    }
}
